import React from "react"
import Layout from "../components/layout"
import SectionTitle from "../components/sectionTitle"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const Intro = ({ title, subtitle, intro }) => (
  <div className="text-center mb-10 border-b pb-10">
    <SectionTitle>{title}</SectionTitle>
    <p>- {intro} -</p>
    <p className="font-bold">{subtitle}</p>
  </div>
)

const Block = ({ title, contentHtml }) => {
  return (
    <div className="mb-8">
      <h3 className="font-bold text-2xl leading-relaxed">{title}</h3>
      <div
        className="leading-relaxed"
        dangerouslySetInnerHTML={{ __html: contentHtml }}
      ></div>
    </div>
  )
}

const Privacy = ({ data }) => {
  let { title, subtitle, intro, repeater } = data.takeshape.getPrivacy
  console.log({ repeater })
  return (
    <Layout>
      <SEO title="Privacy" />
      <div className="container mx-auto px-5">
        <Intro title={title} subtitle={subtitle} intro={intro} />
        {repeater.map(({ title, contentHtml }, index) => (
          <Block key={index} title={title} contentHtml={contentHtml} />
        ))}
      </div>
    </Layout>
  )
}

export default Privacy

export const pageQuery = graphql`
  {
    takeshape {
      getPrivacy {
        subtitle
        title
        intro
        repeater {
          contentHtml
          title
        }
      }
    }
  }
`
